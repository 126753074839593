.progress-bar {
    width: 100%;
    height: 10px;
    background-color: #ddd;
    cursor: pointer;
}

.progress-filled {
    height: 100%;
    background-color: #4CAF50;
}

.time-display {
    margin-top: 8px;
    text-align: center;
}