.sidebar-container {
    justify-content: space-between;
    background: rgb(255, 255, 255);
    color: rgb(52, 58, 64);
    width: min(215px, 100vw);
    overflow: hidden auto;
    border-right: 1px solid rgb(233, 236, 239);
    transition: all 0.2s ease-out 0s !important;
}

button.toggle-btn.show-hide-btn {
    background-color: transparent;
    border: none;
    width: 40px;
}

.sidebar_container {
    display: flex;
}

.responsive-display {
    display: none;
}

@media screen and (max-width:767px) {
    .responsive-display {
        display: block;
        z-index: 9;
    }

    .sidebar-container {
        display: none;
    }

    .sidebar-container.open {
        display: block;
        position: absolute;
        z-index: 3;
    }

    ul#menu {
        margin-bottom: auto !important;
        align-items: flex-start !important;
    }

    .sidebar_container {
        flex-direction: column;
    }
}