.access_container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.permission-access{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0px;
}
.sub_access_icon.permission {
    background: linear-gradient(72deg, rgb(29, 169, 230) 0%, rgb(105, 102, 254) 82%);
}
.overlay.permission.open {
    display: none;
}
.sub_access_icon.permission svg {
    color: #fff;
    font-size: 30px;
}
.sub_access_icon svg {
    font-size: 28px;
    color: #fff;
}
.overlay.permission {
    background: rgba(0, 0, 0, 0.8);
}
.access_icon {
    display: flex;
    justify-content: center;
    align-self: center;
    gap: 20px;
}

.sub_access_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background-color: #e1e1e1b8;
}

.sub_access_icon i {
    color: #fff;
    font-size: 30px;
}

.access_btn button {
    gap: 15px;
    color: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgb(29, 169, 230) 0%, rgb(105, 102, 254) 82%);
    z-index: 1;
    border-radius: 6px;
    white-space: nowrap;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out 0s;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 15px;
}

.access_btn button:hover {
    background-color: rgb(78, 76, 187);
    color: #fff;
}

.refference_video a {
    color: rgb(78, 76, 187);
    text-decoration: none;
    font-weight: 700;
}

.refference_video a:hover {
    color: rgb(78, 76, 187);
    text-decoration: none;
}