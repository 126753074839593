 .theme_color {
     color: #295c88;
     background-color: transparent !important;
 }

 tr.theme_Bg {
     color: #295c88;
     background-color: #fff !important;
     border: none !important;
     box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
 }

 .main-container header {
     background-color: rgb(41, 92, 136);
 }

 .theme_btn {
     text-transform: uppercase;
     border-radius: 25px;
     cursor: pointer;
     font-weight: 600;
     letter-spacing: 1px;
     color: rgb(255, 255, 255);
     transition: padding 0.25s ease-in-out 0s;
     border: 2px solid transparent;
     user-select: none;
     white-space: nowrap;
     /* background: rgb(41, 92, 136); */
     width: 90px;
     min-height: 2rem;
     padding: 6px 18px;
     font-size: 12px;
     appearance: none !important;
 }

 .theme_btn:hover {
     background: rgb(255, 255, 255);
     color: rgb(0, 184, 184);
     text-decoration: none;
     border: 2px solid rgb(0, 184, 184);
 }

 svg.svg_icons {
     width: 10px;
     fill: #fff;
     margin-left: 5px;
 }

 /* svg.svg_icons:hover {
            fill: #000;
        } */
 button.theme_btn.btn:hover .svg_icons {
     fill: rgb(0, 184, 184);
 }

 @media only screen and (max-width: 600px) {
     .resposive_row {
         display: flex;
         flex-direction: column;
         justify-content: center;
     }

     .resposive_td {
         text-align: center !important;
     }
 }

 .popup {
     position: fixed;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     display: flex;
     justify-content: center;
     align-items: center;
     background: rgba(0, 0, 0, 0.5);
     color: white;
     font-size: 1.5em;
 }