* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


/* ============= home page css start ============== */

.home-heading h1 {
    color: #524FFC;
    background-image: linear-gradient(90deg, #1da9e6, #6966fe 82%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
}

.start-btn a {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    width: fit-content;
    min-width: unset;
    line-height: 1.3;
    padding: 0.8em 2em;
    transition: all 0.2s ease-in-out 0s;
    font-family: poppins, Inter, "Helvetica Neue", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 16px;
    color: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgb(29, 169, 230) 0%, rgb(105, 102, 254) 82%);
    z-index: 1;
    border-radius: 25px;
    white-space: nowrap;
    font-weight: 600;
}

/* ============= Form css start ============== */

.form-continer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.start-form {
    padding: 20px;
    border-radius: 8px;
    /* width: min(385px, 100%); */
    background-color: rgb(246, 248, 255);
    filter: drop-shadow(rgba(33, 37, 41, 0.16) 1px 2px 5px);
}



/* ============= Form css end ============== */

/* =============Dashboard css start ============== */

.small-card {
    /* flex: auto; */
    width: calc(33.33% - 28px);
    font-size: 22px;
    padding: 32px 64px;
    background-color: rgb(255, 255, 255);
    color: rgb(4, 3, 72);
    font-weight: 600;
    line-height: 1.3;
    border-radius: 10px;
    transition: transform 0.3s ease 0s, box-shadow 0.3s ease 0s, background-color 0.3s ease 0s;
    border: none;
}

.small-card:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
    background-color: rgb(246, 248, 255);
    transform: scale(1.025);
}

.card-gap {
    gap: 28px;
}

.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.right_section {
    height: 100%;
    min-height: 100vh;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(4, 3, 72);
}

.spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 8px solid black;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.card {
    width: 100%;
    padding: 20px 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.card-footer {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    border: none;
}

.spinner-section {
    height: 100vh;
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center;
}

.camera-width-con {
    width: 100%;
    height: 350px;
    border-radius: 12px;
}

.camera-width-con video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.camera-width-con img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.camera-btns-contrl {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px 12px;
    cursor: pointer;
    min-width: unset;
    line-height: 1.3;
    padding: 0.5em 1em;
    transition: all 0.2s ease-in-out 0s;
    color: rgb(255, 255, 255);
    background-image: linear-gradient(90deg, rgb(29, 169, 230) 0%, rgb(105, 102, 254) 82%);
    z-index: 1;
    background-color: unset;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    height: 48px;
    width: calc(50% - 24px);
    margin: 10px 10px;
}

.demo_Qns {
    position: relative;
    border: 1px solid rgb(233, 236, 239);
    box-shadow: rgba(33, 37, 41, 0.16) 1px 2px 5px;
    border-radius: 12px;
    padding: 24px;
    background-color: rgb(246, 248, 255);
}

.demo_Qns h2 {
    text-align: center;
}

.que-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.que-btn button {
    border: none;
    border-radius: 5px;
    padding: 0px 10px;
}

.demo_Qns p {
    font-size: 18px;
    line-height: 1.5;
    font-weight: 600;
}

.top_heading::after {
    border-bottom: 1px solid gainsboro;
    display: block;
}

section.practice-section {
    height: 100vh;
    display: flex;
    margin: auto;
    text-align: center;
    align-items: center;
}

@media only screen and (max-width: 600px) and (min-width: 280px) {
    .small-card {
        width: 100%
    }

    .card-gap {
        gap: 5px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 280px) {
    .form-continer {
        margin-top: 20px;
    }

    .form-right-img {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 601px) {
    .small-card {
        width: calc(50% - 28px);
    }
}

@media only screen and (max-width: 992px) {
    .camera-width-con {
        height: 250px;
    }
}

/* ============= Dashboard css end ============== */