* {
    font-family: "Kanit", sans-serif;
}



/* ====== main section css   =========== */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    /* White background with some transparency */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.popup {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.popup button {
    margin-top: 10px;
}

section.d-flex.content-justify-center.m-auto {
    /* height: 100vh; */
    align-items: center;
    width: 100%;
    justify-content: center;
}

.main-bg {
    background-color: #e0993c;
}

.item h3 {
    font-size: 2.5rem;
}

.item {
    display: block;
}

.item.active {
    display: block;
}

.inner-content p {
    line-height: 1.5;
    font-size: 18px;
}

.btn-mobail {
    display: none;
}

@media only screen and (max-width: 768px) {
    .item h3 {
        font-size: 1.5rem;
    }

    .btn-desk {
        display: none;
    }

    .btn-mobail {
        display: block;
    }
}

.common-black-button {
    width: clamp(200px, 16vw, 290px);
    color: #fff;
    background-color: #000;
    padding: 10px 15px;
    transition: transform 0.3s;
}

.common-black-button:hover {
    color: #fff;
    transform: scale(1.05);
}

.inner-left {
    position: relative;
}

.btn-mobail-con {
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
}


/* ====== main section end   =========== */


/* ====== footer section css   start =========== */

.footer-feeds {
    padding: 50px 17px;
}

footer {
    background-color: rgb(23, 23, 23);
}

.social-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.font-icon {
    margin: 0px 20px;
}

.font-icon i {
    color: #fff !important;
    font-size: 24px;
}

.footer-links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.footer-links span {
    color: #fff;
}

.links-feed a {
    text-decoration: none;
    color: #fff;
}

.links-feed a:hover {
    text-decoration: none;
    color: #fff;
}

.web-link p {
    color: #636c72 !important;
}

.copyright p {
    color: #fff;
}

.main-top-icon {
    width: 250px;
    height: 156px;
}

.main-top-icon img {
    width: 100%;
    height: 100%;
}


/* ====== footer section css end =========== */


/* ====== form section start =========== */

.signup-form {
    width: 450px;
    margin: 0 auto;
    padding: 0px;
    font-size: 15px;
    max-width: 100%;
    transition: 0.3s;
    border-radius: 6px;
    border-radius: 6px;
    background: linear-gradient(3deg, rgb(245 245 245) 0%, rgb(247 246 246 / 29%) 100%);
}

.signup-form h2 {
    margin: 0 0 15px;
    position: relative;
    text-align: center;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.9);
    padding: 10px 0 0 0;
    font-size: 26px;
}

.signup-form h2:before,
.signup-form h2:after {
    content: "";
    height: 2px;
    width: 25%;
    background: #e9e9e9;
    position: absolute;
    top: 55%;
    z-index: 2;
}

.signup-form h2:after {
    right: 0;
}

.signup-form h2::before {
    left: 0;
}

.form-groupp>input,
.form-group>input {
    border: none !important;
    border-bottom: 1px solid #000 !important;
    font-weight: 400 !important;
    padding: 10px 10px 5px 0px !important;
    min-width: 100% !important;
    background: transparent !important;
    letter-spacing: 1px !important;
    outline: none !important;
    font-size: 14px !important;
    border-radius: 0 !important;
}

.signup-form form {
    margin-bottom: 15px;
    padding: 30px;
}

.accept-terms-label .form-check-label>a,
.accept-terms-label .form-check-label {
    font-size: 12px !important;
}

.btn-custom {
    color: #fff !important;
    background-color: #e0993c !important;
    font-weight: 600;
    padding: 0.6rem 0.95rem !important;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%) !important;
    transition: all 0.15s ease-in-out;
    font-weight: 600 !important;
}

.btn-custom:hover {
    transform: scale(1.04);
}

.accept-terms-label .pl-0 {
    min-width: 100%;
}

#agree {
    position: relative;
    top: 4px;
}


.form-control {
    margin-bottom: 15px;
    ;

}

/* ====== form section end =========== */

/* ======  UC section  =========== */
.heading-user-form {
    margin-bottom: 50px;
}

.detail-label {
    font-weight: 600;
    width: 150px;
}

.questionslist {
    margin-top: 100px;
}

/* ======  UC section  =========== */


.video-player {
    margin: auto;
}



.enablecamera {
    margin: auto;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 100vh;
    font-size: 20px;
    margin: auto;
    padding: 20px;
    width: 100%;
    background: linear-gradient(90deg, #494364 40%, #9387CA 90%);
    flex-direction: column;

}

.camera_error {
    margin-top: 40px;
    /* width: 50%; */
    text-align: center;
    padding: 0px 90px;
    font-weight: 400;
    font-family: "Jockey One", sans-serif;
    font-style: normal;
    font-size: 24px;
}








/* Add smooth transitions */
.card,
.video-player,
h1,
h3,
video {
    transition: all 0.5s ease-in-out;
}

/* Animation for fading in elements with scale */
@keyframes fadeInScale {
    0% {
        opacity: 0;
        transform: translateY(20px) scale(0.95);
    }

    100% {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}

/* Apply the fadeInScale animation */
.card,
.video-player,
h1,
h3,
video {
    animation: fadeInScale 0.5s;
}

/* Additional styles for better appearance */
.card {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    padding: 20px;
    margin-top: 10px;
    border-radius: 10px;
    background-color: #fff;
    height: 100vh;
}

/* Enhance button appearance */
.button {
    transition: background-color 0.3s, transform 0.3s;
}

.button:hover {
    background-color: #007bff;
    transform: scale(1.05);
}

.video-player video {
    width: 100%;
    border-radius: 8px;
}

/* Style for the wait timer div */
.wait-timer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    padding: 30px;
    margin-top: 50px;
    /* background-color: #dca45b72; */
}

.wait-timer h2 {
    margin-bottom: 20px;

}



.Wait-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 300px;
}


.svg-styles {
    margin: auto;
    display: flex;
    justify-content: center;
    background-color: transparent;
}

.checkyour_email {
    color: #000;
    text-align: center;
}

.card-height-set {
    line-height: 0px;
}

.video-player {
    position: relative;
}

.Wait_timer_clock {
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    opacity: 0.7;

}

/* 
.center-button:hover {
    background-color: #0056b3;
    
    transform: translate(-50%, -50%) scale(1.1);
    
    opacity: 1;
    background-color: #5f38ebf0;
} */

.go_home_modal {
    height: 100vh;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}




/* Hide the main content on screens smaller than 768px */
@media (max-width: 768px) {
    .sidebar_container {
        display: none !important;
    }

    .mobile-warning {
        background: #ffffdd;
        padding: 20px;
        align-items: center;
        display: flex;
        justify-content: center;
        height: 100vh;
        margin: auto;
        text-align: center;
        justify-content: center;
        display: flex;
        align-items: center;
        height: 100vh;
        font-size: 20px;
        margin: auto;
        padding: 20px;
        width: 100%;
        background: linear-gradient(90deg, #494364 40%, #9387CA 90%);
        flex-direction: column;
    }
}

/* Show the main content on screens 768px and larger */
@media (min-width: 769px) {
    .sidebar_container {
        display: block;
    }

    .mobile-warning {
        display: none;
    }
}


.slow_internet {
    HEIGHT: 100vh;
    margin: auto;
    text-align: center;
    background: #fff2da;
    display: flex;
    align-items: center;
    justify-content: center;

}

.test_open_box {
    justify-content: center;
}



/* button css */
.center-button {

    color: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgb(29, 169, 230) 0%, rgb(105, 102, 254) 82%);
    width: 250px;
    padding: 12px;
    border-radius: 25px;
    border: transparent;
    margin: auto;
}

.button_box {
    width: 100%;
    margin: auto;
    text-align: center;
}

.watch_image {

    width: 205px !important;
    margin-bottom: 20px;
}

.question-page {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-style: normal;
    max-height: 65px;
}

.answer_time {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.clock_center {
    margin: 2% auto;
}


.box-above-background {
    width: 60%;
    background-color: white;
    border-radius: 15px;
    padding: 30px 0px;

}

.mobile-box-above-background {
    width: 100%;
    background-color: white;
    border-radius: 15px;
    padding: 30px 0px;
}


.mobile_availablity_error {
    margin-top: 40px;
    /* width: 50%; */
    text-align: center;
    padding: 0px 20px;
    font-weight: 400;
    font-family: "Jockey One", sans-serif;
    font-style: normal;
    font-size: 24px;
}

@media only screen and (max-width: 600px) {
    .camera_error {
        margin-top: 40px;
        text-align: center;
        padding: 0px 10px;
        font-weight: 400;
        font-family: "Jockey One", sans-serif;
        font-style: normal;
        font-size: 18px;
    }

    .box-above-background {
        width: 100%;
        background-color: white;
        border-radius: 15px;
        padding: 30px 0px;

    }
}

.thankyouimg_box {

    margin: auto;
    text-align: center;
}

.social_icons svg {
    width: 20px;
}

.social_icons {
    display: flex;
    gap: 15px;
    justify-content: center;
    margin: 30px 0px;
}

a {
    text-decoration: none;
}

.go_home_modal {
    height: 100vh;
}

.modal-dialog {
    margin-top: 2% auto;
    max-width: 1000px;
}

.modal-body {
    padding-bottom: 60px;
}

.modal_button_center {
    display: flex;
    justify-content: centers;
}

.card {
    margin-top: 0px;
    ;
}

.camera-width-con video {

    margin-bottom: 20px;
}


.kanit-medium {
    font-family: "Kanit", sans-serif;
    font-weight: 500;
    font-style: normal;
}

#thankyou-box {
    background: linear-gradient(300deg, #494364 40%, #9387CA 90%);
    Height: 100vh;
    display: flex;
    align-items: center;
}

.start_test_btns {
    color: #fff;

}

.test_instructions {
    height: 650px;
    background: linear-gradient(300deg, #494364 40%, #9387CA 90%);
    display: flex;
    padding: 90px 50px;
    text-align: center;
    margin-bottom: 50px;
    width: 100%;
    justify-content: space-around;
}

.test_instructions img {
    width: 400px;
    height: 270px;
    border-radius: 15px;
}

.test_instructions h1 {
    margin-bottom: 20px;
    ;
}

.box_for_test {
    display: flex;
    justify-content: space-around;
    width: 94%;
    padding: 40px 30px;
    background-color: white;
    border-radius: 35px;
    align-items: center;
}

.box_for_test div {
    text-align: left;
}

.thankyou_section_main {
    background-color: white;
    width: 90%;
    padding: 30PX 50PX;
    border: 25px solid #0e12ff2e;
    border-radius: 60px;
}

.internal_block_box {
    width: 650px;
}

.internal_block_box .card {
    padding: 1px 30px;
    box-shadow: none;
}

.internal_block_box img {
    width: 350px;
    height: 250px;
    border: none;
    /* padding: 20px; */
}

.container_sign {
    background: linear-gradient(300deg, #494364 40%, #9387CA 90%);
    min-height: 100vh;
    height: auto;
}

.detail_elem div {
    width: 74%
}

.detail_elem {
    display: flex;
}

/* .start-form {
    border: 5px solid;
    border-radius: 50px !important;
    border-color: linear-gradient(90deg, rgb(29, 169, 230) 0%, rgb(105, 102, 254) 82%);
} */


.start-form {
    border: 5px solid transparent;
    /* Set a transparent border */
    border-radius: 20px;
    /* Adjust the border radius as needed */
    border-image-slice: 1;
    /* border-image-source: linear-gradient(90deg, rgb(29, 169, 230) 0%, rgb(105, 102, 254) 82%); */
}

.card-img-top {
    width: 250PX;
}

.detail_container_box {
    min-height: 100vh;
    height: auto;
    padding: 30px 20px;
}


.error {
    color: red;
    margin: 20px;
}